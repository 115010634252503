.scrollbar {
    scrollbar-width: initial;

    scrollbar-color: #f0703a #e2e8f0;

}


.scrollbar::-webkit-scrollbar {
    width: 10px;

}

.scrollbar::-webkit-scrollbar-track {
    background: #e2e8f0;

}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #f0703a;

    border-radius: 10px;

}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #e65e3b;

}